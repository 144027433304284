import { isMobile } from '../utils/index'
export default {
  address: '台北市文山區羅斯福路五段216號之2號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1807.9674460345761!2d121.53746605862119!3d25.002328214429987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa1d136e1fb5%3A0x8bc6de2b2de9cc7c!2zMTE25Y-w5YyX5biC5paH5bGx5Y2A576F5pav56aP6Lev5LqU5q61MjE26Jmf!5e0!3m2!1szh-TW!2stw!4v1612773335927!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/7jRLW7dWRUQpvdG77',
  phone: '02-2933-1888',
  fbLink: 'https://www.facebook.com/%E8%87%AA%E6%85%A2%E8%97%8F-338064510833000',
  fbMessage: 'https://m.me/338064510833000',
  caseName: '自漫藏',
  indigatorLength: 10,
  
  houseInfos: [
    ['投資興建', '元家集團-沅利建設',],
    ['建築設計', '彭繼賢建築師事務所',],
    ['營造工程', '日本國土開發營造商',],
    ['建照號碼', '107建字第0132號',],
    ['結構安全', '聯邦工程顧問',],
    ['結構外審', '台灣大學工學院地震研究中心',],
    ['景觀設計', '頤和設計',],
    ['園藝顧問', '大漢景觀設計',],
    ['不動產信託', '永豐商業銀行',],
    ['規劃坪數', '45坪3房、60坪雙主臥',],
    ['企劃銷售', '得邦廣告',],
   
  ], 

  gtmCode: ['KT3B2F7'], // 可放置多個
  //得幫金鑰
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約賞屋' : '預約賞屋',
    subTitle: ''
  }
}
